@import './../../styles/colors';

.drop-file {
  &_items {
    &_item {
      display: flex;
      align-items: center;
      &_image {
        height: 100px;
        max-width: 200px;
        overflow: hidden;
        border: 1px solid $color-medium;
        border-radius: 5px;
        margin-bottom: 10px;
        &_img {
          display: block;
          width: auto;
          height: 100%;
          cursor: pointer;
        }
      }
      &_file {
        margin-left: 10px;
        padding: 10px;
        border-radius: 10px;
        font-size: 18px;
        &_path {
          font-size: 12px;
          color: $color-normal;
        }
        &_size {
          font-size: 12px;
          color: $color-medium;
        }
      }
    }
  }

  &_zone {
    padding: 10px 40px;
    border: 1px dashed $color-medium;
    border-radius: 20px;
    height: 200px;
    display: flex;
    align-items: center;
    background-color: $color-light;
    margin-bottom: 10px;
  }
}
