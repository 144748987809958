.permissions {
  &_header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    &_button {
      justify-self: end;
    }
  }

  &_active {
    cursor: pointer;
  }
}
