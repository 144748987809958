@import '../../../styles/colors';
@import '../../../styles/variables';

.menuitem {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: $color-light;
  font-size: 12px;
  height: 40px;
  padding: 0 10px 0 20px;
  cursor: pointer;
  &_icon {
    width: 25px !important;
    height: 25px !important;
    margin-right: 5px;
  }
  &_label {
  }
}
