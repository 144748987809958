@import './../../styles/colors';

.pagetitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  flex-grow: 1;
  &_title {
    min-width: max-content;
    padding: 0 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &_backicon {
      display: flex;
      flex-direction: row;
      align-items: center;
      &_icon {
        color: $color-normal;
        font-size: 25px !important;
        padding: 0;
      }
    }
    &_text {
      font-size: 20px;
      color: $color-normal;
      // text-transform: capitalize;
    }
  }
}
