@import 'colors';

@font-face {
  font-family: 'OpenSans-Regular';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  src: local('OpenSans-Regular'),
    url('../assets/fonts/OpenSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'OpenSans-Bold';
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  src: local('OpenSans-Bold'),
    url('../assets/fonts/OpenSans-Bold.ttf') format('truetype');
}

body {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  background-color: $color-light;
  height: 100vh;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
