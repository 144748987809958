.rolePermissions {
	&_header {
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
	}
	&_active {
		cursor: pointer;
	}
}
