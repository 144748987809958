@import '../../styles/colors';

.emailvalidation {
	display: flex;
	flex-direction: row;
	height: 100vh;
	align-items: center;
	justify-content: center;
	background-color: $color-secondary;

	&_container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: space-between;
		padding: 20px;

		background-color: $color-white;
		border-radius: 10px !important;
		width: 400px;
		overflow: hidden;

		&_title {
			font-size: 20px;
			color: $color-primary;
			margin-bottom: 20px;
			&.error {
				color: $color-delete;
			}
		}

		&_logos {
			text-align: center;
			width: 200px;
		}
	}
}
