$color-primary: #6d6253;
$color-secondary: #151515;
$color-complementary: #decb40;
$color-complementary-2: #ced6de;
$color-complementary-3: #687d95;
$color-complementary-4: #475b7a;
$color-light: #f0f0f0;
$color-white: #ffffff;
$color-dark: #28292b;
$color-medium: #adadad;
$color-normal: #707070;
$color-disabled: #e4e4e4;
$color-cancel: #adadad;
$color-delete: #fa6347;
$color-check: #3bbb3e;

//$color-primary: #193552;
