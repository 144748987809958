.certificatemodal {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: repeat(auto-fit, auto);
	grid-row-gap: 25px;
	grid-gap: 10px;
	//height: max-content;
	&_active {
		justify-self: end;
		grid-column: 1/4;
	}
	#MUI_name {
		grid-column: 1/3;
	}
	#MUI_address {
		grid-column: 1/3;
	}
	#MUI_location {
		grid-column: 1/3;
	}
	#MUI_email {
		grid-column: 1/3;
	}
	#MUI_company {
		grid-column: 1/3;
	}
}
