@import './../../styles/colors';

.logos {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;

	&_mediata {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 100%;
		margin-right: 2%;
		&_img {
			width: 100%;
			height: 100%;
		}
	}
}
