@import '../../styles/colors';

.confirmModal {
  border-radius: 20px !important;
  &_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $color-white;
    border-radius: 20px !important;
    width: 400px;
    // height: 200px;
    padding: 20px;

    &_title {
      font-size: 20px;
      margin-bottom: 20px;
    }

    &_icon {
      place-self: center;
      height: 80px !important;
      width: 80px !important;
      color: $color-complementary;
    }

    &_message {
      font-size: 16px;
      margin-top: 20px;
      text-align: center;
    }
  }
}
